<template>
  <svg
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M8.5 6C8.5 7.38125 7.38125 8.5 6 8.5C4.61875 8.5 3.5 7.38125 3.5 6C3.5 4.61875 4.61875 3.5 6 3.5C7.38125 3.5 8.5 4.61875 8.5 6ZM6 5C5.44688 5 5 5.44688 5 6C5 6.55312 5.44688 7 6 7C6.55312 7 7 6.55312 7 6C7 5.44688 6.55312 5 6 5ZM12 6C12 8.73125 8.34375 13.5938 6.74062 15.6C6.35625 16.0781 5.64375 16.0781 5.25938 15.6C3.62813 13.5938 0 8.73125 0 6C0 2.68625 2.68625 0 6 0C9.3125 0 12 2.68625 12 6ZM6 1.5C3.51562 1.5 1.5 3.51562 1.5 6C1.5 6.3875 1.64031 6.9875 1.97812 7.7875C2.30562 8.5625 2.77063 9.41875 3.30625 10.2844C4.19375 11.7281 5.225 13.0969 6 14.1156C6.775 13.0969 7.80625 11.7281 8.69375 10.2844C9.22813 9.41875 9.69375 8.5625 10.0219 7.7875C10.3594 6.9875 10.5 6.3875 10.5 6C10.5 3.51562 8.48438 1.5 6 1.5Z"
    />
  </svg>
</template>
